import simpleParallax from 'simple-parallax-js';

export default {
    mounted(el, binding, vnode, prevVnode) {
        el.parallax = new simpleParallax(el, binding.value);
    },

    beforeUnmount(el, binding, vnode, prevVnode) {
        el.parallax.destroy();
    },
};
