// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================

import { createApp, defineAsyncComponent } from 'vue';
import apolloProvider from './apollo';

// Animate on scroll
import AOS from 'aos';

// Components
import Rot13Email from './components/Rot13Email.vue';
const Odometer = defineAsyncComponent(() => import('./components/Odometer.vue'));
const Projects = defineAsyncComponent(() => import('./components/Projects.vue'));
const VideoPlayer = defineAsyncComponent(() => import('./components/VideoPlayer.vue'));

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// Vue masonry
import { VueMasonryPlugin } from 'vue-masonry';

// Collapse transition
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

import parallax from '@/js/directives/parallax';

window.AOS = AOS;

const app = createApp({
    delimiters: [ '${', '}' ],

    data() {
        return {
            isMenuOpen: false,
            isSearching: false,
            animationsInitialized: false,
            isScrolling: window.scrollY > 0,
            swiperModules: [ Autoplay, Pagination, Navigation ],
            activeNode: null,
            showCompetitionModal: false,
        };
    },

    mounted() {
        window.addEventListener('scroll', this.onScroll);

        if (window.outerWidth < 768) {
            this.initAnimations();
            return;
        }

        if (location.pathname !== '/') {
            setTimeout(() => {
                this.initAnimations();
            }, 300);
        }
    },

    beforeUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    },

    methods: {
        onScroll() {
            this.isScrolling = window.scrollY > 0;
        },
        onPlayVideo() {
            setTimeout(() => {
                this.initAnimations();
            }, 300);
        },
        initAnimations() {
            if (this.animationsInitialized) {
                return;
            }

            this.animationsInitialized = true;

            AOS.init({
                disable: window.outerWidth < 768,
                duration: 600,
            });
        },
        async onToggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
            if (this.isMenuOpen) {
                await this.$nextTick();

                setTimeout(() => {
                    this.$refs.mobileMenu.style['-webkit-backdrop-filter'] = 'blur(10px)';

                    setTimeout(() => {
                        this.$refs.mobileMenu.style['-webkit-backdrop-filter'] = 'blur(9px)';
                    }, 10);
                }, 10);
            }
        },
        onToggleNode(id) {
            if (this.activeNode === id) {
                this.activeNode = null;
                return;
            }

            this.activeNode = id;
        },
        onToggleSearch(mobile) {
            this.isSearching = !this.isSearching;

            if (mobile) {
                this.$refs.searchInputMobile.focus();
            } else {
                this.$refs.searchInput.focus();
            }
        },
        onSearchBlur() {
            this.isSearching = false;
        },
    },
});

app.directive('parallax', parallax);

app.component('Swiper', Swiper);
app.component('SwiperSlide', SwiperSlide);
app.component('Rot13Email', Rot13Email);
app.component('Odometer', Odometer);
app.component('Projects', Projects);
app.component('VideoPlayer', VideoPlayer);
app.component('CollapseTransition', CollapseTransition);

app.use(VueMasonryPlugin);
app.use(apolloProvider);

app.mount('#app');
